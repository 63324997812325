.headerLayout {
  -webkit-box-shadow: 0px 10px 22px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 22px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 22px -15px rgba(0, 0, 0, 0.75);
}
.navbarOpen {
  // width: 100% !important;
  .offcanvas-header {
    .btn-close {
      font-size: 20px;
      padding-right: 50px;
    }
  }
}

.headerNavBar {
  a {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    color: black;
    text-decoration: none;
    @media (max-width: 991px) {
      font-size: 25px;
    }
    &:hover,
    &.active {
      color: #00a5f7;
    }
  }
}
