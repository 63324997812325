@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

.mainLayout {
  min-height: calc(100vh - 84px);
  padding-top: 86px;
  padding-bottom: 50px;
}
.textSection {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
  text-align: left;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  p {
    padding: 0 20px 0 0;
  }
  .shotImages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 30px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    img {
      max-width: 250px;
      @media (max-width: 767px) {
        max-width: 150px;
      }
    }
  }
}
