.headingLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    padding-top: 30px;
    width: 100%;
    max-width: 100px;
  }
  .heddingInfo {
    font-size: 50px;
    padding: 25px 0 10px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 30px;
      font-weight: 600;
    }
  }
  .graphic {
    padding-top: 20px;
    width: 100%;
    max-width: 400px;
  }
  .pdfDownload {
    margin-top: 30px;
    font-size: 20px;
    padding: 7px 25px;
  }
}
